import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/course/CourseTutor.css';
import plus from '../../assets/images/PlusCircle.png';
import minus from '../../assets/images/MinusCircle.png';

const CourseTutor = ({ faqs }) => {
  const [showAnswers, setShowAnswers] = useState([]);

  const toggleAnswer = (index) => {
    const updatedShowAnswers = [...showAnswers];
    updatedShowAnswers[index] = !updatedShowAnswers[index];
    setShowAnswers(updatedShowAnswers);
  };

  return (
    <>
      <div className="coursetutor-main">
        <div className="coursetutor-heading">
          {/* <h3>{course.tutor}</h3> */}
        </div>
       
        {faqs.map((faq, index) => (
          <div key={index}>
            <div className="coursetutor-plus" onClick={() => toggleAnswer(index)}>
              <p>{faq.question}</p>
              {showAnswers[index] ? <img src={minus} alt="Minus Icon" /> : <img src={plus} alt="Plus Icon" />}
            </div>
            
            {showAnswers[index] && (
              <div className='coursetutor-minus'>
                <p className='coursetutor-p'>{faq.answer}
             
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
      <hr />
    </>
  );
};

CourseTutor.propTypes = {
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      tutor: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CourseTutor;
