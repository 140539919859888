import React from 'react'
import Featured from './homeComponents/Featured'
import Banner1 from './homeComponents/Banner1'
import Banner2 from './homeComponents/Banner2'
import Training3 from './homeComponents/Training3'
import Training from './homeComponents/Training'

const Home = () => {
  return (
    <div className='home-main'>
      <Banner1/>
      <Banner2/>
      <Training/>
      <Training3/>
      <Featured/>
    </div>
  )
}

export default Home
