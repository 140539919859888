import React from 'react';
import '../../styles/home/Training3.css';
import video from '../../assets/logo/VideoIcon.png';
import { Link as ScrollLink } from 'react-scroll';

const Training3 = () => {
  return (
    <div className='training-main'>
      <h1>Join CodeCuckoo, where your learning journey is all about you.</h1>
      <div className='t3-btn-main'>
        <ScrollLink to='features' smooth={true} className='t3-btn1' duration={500}>
          <button className='t3-btn1'>Get Started</button>
        </ScrollLink>
        <div className='t3-btn2'>
          <img src={video} alt='' />
          <button>Watch Video</button>
        </div>
      </div>
      <p>Let’s create a future where you lead and excel, one personalized training session at a time.</p>
    </div>
  );
};

export default Training3;
