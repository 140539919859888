import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/course/CourseDetails.css';

const CourseDetails = ({ eligibility, courseContent }) => {
  return (
    <div className="coursedetails-main">
      <div className="coursedetails-1">
        <h2>Eligibility</h2>
        <p>{eligibility}</p>
      </div>
      <div className="coursedetails-1">
        <h2>Course Content</h2>
        <ol>
          {courseContent.map((content, index) => (
            <li key={index}>{content}</li>
          ))}
        </ol>
      </div>
    </div>
  );
};


CourseDetails.propTypes = {
  eligibility: PropTypes.string.isRequired,
  courseContent: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CourseDetails;
