import React from 'react'
import CourseHead from './courseComponents/CourseHead'
import CourseDetails from './courseComponents/CourseDetails'
import CourseSchedule from './courseComponents/CourseSchedule'
import CourseTutor from './courseComponents/CourseTutor'
import CourseTutor1 from './courseComponents/CourseTutor1'
import CourseAbout from './courseComponents/CourseAbout'

const Courses = () => {
  return (
    <div>
      <CourseHead/>
      <CourseDetails/>
      <CourseSchedule/>
      <CourseTutor1/>
      <CourseTutor/>
      <CourseAbout/>
    </div>
  )
}

export default Courses
