import React from 'react'
import CourseScheduleCard from './CourseScheduleCard';
import '../../styles/course/CourseSchedule.css';

const CourseSchedule = () => {
  return (
    <>
    <div className='courseschedule-main'>
      <h2>Course Schedule</h2>
      <CourseScheduleCard week={'Week 1-2'} text={'Introduction to microservices and architectural patterns.'}/>
      <CourseScheduleCard week={'Week 3-4'} text={'Designing microservices with Domain-Driven Design (DDD).'}/>
      <CourseScheduleCard week={'Week 5-6'} text={'Developing microservices using popular frameworks.'}/>
      <CourseScheduleCard week={'Week 7'} text={'Deploying and scaling microservices with Docker and Kubernetes.'}/>
      <CourseScheduleCard week={'Week 8-9'} text={'Microservices security, testing, and monitoring.'}/>
      <CourseScheduleCard week={'Week 10'} text={'Case studies and final project.'}/>
    </div>
     <hr/>
    </>
  )
}

export default CourseSchedule
