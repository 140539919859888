import React from 'react';
import '../../styles/course/CourseScheduleCard.css';

const CourseScheduleCard = ({ week, text }) => {
  return (
    <div className='courseschedule-card'>
      <h3>{week}</h3>
      <p>{text}</p>
    </div>
  );
}

export default CourseScheduleCard;
