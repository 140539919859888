import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Courses from './components/Courses';
import Course from './components/Course';

import coursesData from './components/CoursesData'; 
import ContactUs from './components/ContactUs';
import Login from './components/Login';
function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/contact' element={<ContactUs/>} /> 
        <Route path='/login' element={<Login/>} /> 
        {coursesData.map(course => (
          <Route key={course.id} path={`courses/${course.id}`} element={<Course course={course} />} />
        ))}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
