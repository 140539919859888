import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/course/CourseTutor1.css';
import electic from '../../assets/images/Electric.png';
import girl from '../../assets/images/Girl.png';

const CourseTutor1 = ({ tutor, tutorInfo, tutorUrl }) => {
  return (
    <>
      <div className="main-container">
        <div className="text-container">
          <h3 className="headingt">{tutor}</h3>
          <p className="taglinep">{tutorInfo}</p>

          <div className="stats-container">
            <div className="stat-item">
              <span className="stat-value">4.7</span>
              <h2 className="stat-label">Rating</h2>
            </div>
            <div className="stat-item">
              <span className="stat-value">20+</span>
              <h2 className="stat-label">Courses</h2>
            </div>
            <div className="stat-item">
              <span className="stat-value">20k+</span>
              <h2 className="stat-label">Students</h2>
            </div>
          </div>
        </div>
        <div className="image-container">
          <img className="icon" src={electic} alt="" />
          {/* <img className="image" src={tutorUrl} alt="" /> */}
        </div>
      </div>
      <hr />
    </>
  );
};

CourseTutor1.propTypes = {
  tutor: PropTypes.string.isRequired,
  tutorInfo: PropTypes.string.isRequired,
};

export default CourseTutor1;
