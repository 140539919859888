import React from 'react';
import '../../styles/course/CourseTutor1.css';
import electic from '../../assets/images/Electric.png';
import girl from '../../assets/images/Girl.png';
const CourseTutor1 = () => {
  return (
    <>
    <div className="main-container">
      <div className="text-container">
        <h3 className="headingt">Course Tutor: Isil Celik</h3>
        <p className="taglinep">
            Isil is an experienced software architect with over 15 years in the tech industry. She has worked extensively with microservices architecture and has led multiple teams in developing and deploying robust microservices systems.
        </p>
        <div className="stats-container">
          <div className="stat-item">
            <span className="stat-value">4.7</span>
            <h2 className="stat-label">Rating</h2>
          </div>
          <div className="stat-item">
            <span className="stat-value">20+</span>
            <h2 className="stat-label">Courses</h2>
          </div>
          <div className="stat-item">
            <span className="stat-value">20k+</span>
            <h2 className="stat-label">Students</h2>
          </div>
        </div>
      </div>
      <div className="image-container">
        <img className="icon" src={electic} alt="" /> 
        <img className="image" src={girl} alt="" /> 
      </div>
    </div>
    <hr/>
    </>
  )
}

export default CourseTutor1
