import React from 'react';
import '../../styles/home/Banner2.css';
import girl from '../../assets/images/Girl.png';
import electric from '../../assets/images/Electric.png';
import { Element } from 'react-scroll/modules';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
const Banner2 = () => {
  return (
  <Element name="aboutus">
    <div className='b2-main'>
    
        <div className='b2-img'>
                <img src={girl} alt='' />
        </div>
        <div className='b2-content'>
              <div className='b2-c1'>
                <div>
                  <span>🏆🏆</span>
                  <h1>About CodeCuckoo</h1>
                </div>
                <div>
                    <img src={electric} alt='' />
                </div>
              </div>
              <p>At CodeCuckoo, we believe in the power of technology and the limitless potential it holds. We are dedicated to providing comprehensive, industry-focused courses to enable the builders of tomorrow. With our curated educational content, we aim to make high-quality learning resources accessible to individuals across the globe, helping them adapt to the rapidly evolving digital landscape.</p>
             
             <ScrollLink to={'features'}  smooth={true} className='banner1-btn1' duration={500}><button>Learn More</button></ScrollLink>

        </div>
    </div>
    </Element>
  )
}

export default Banner2
