import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import Link component and useLocation hook from react-router-dom
import img from '../../assets/logo/FeaturedStar.png';
import '../../styles/home/Featured.css';
// import { CiLogin } from 'react-icons/ci';
import Card from './Card';
import img1 from '../../assets/images/CardImg1.png';
import img2 from '../../assets/images/CardImg2.png';
import img3 from '../../assets/images/CardImg3.png';
import img4 from '../../assets/images/CardImg4.png';
import { Element } from 'react-scroll/modules';

const Featured = () => {
  
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Element name="features">
    <div className='featured-main'>
      <div className='featured-heading'>
        <img src={img} alt='' />
        <h1>Our Featured Courses</h1>
      </div>
      <div className='featured-button'>
        <p>Embark on your unique learning journey with our meticulously curated, industry-relevant courses. Here's a sneak peek into our offerings</p>
     
      </div>
      <div className='featured-card'>
        <Link to="/courses" onClick={scrollToTop}>
          <Card img={img2} heading={'Microservices: Designing, Developing, and Deploying'} text={'Master the art and science of microservices, an increasingly relevant architectural style in the tech world. This course will equip you with the practical knowledge to design, develop, and deploy microservices using best industry practices, offering you hands-on experience to build scalable applications.'} />
        </Link>
        <Link to="/courses/1" onClick={scrollToTop}>
          <Card img={img1} heading={'Personalised Learning: From Solution Architect to Startup CEO'} text={'Transform from a skilled Solution Architect to a strategic Startup CEO through personalised, one-on-one coaching. The course is tailored to build essential leadership skills, business strategies, and operations management, providing you a well-rounded perspective of running a startup.'} />
        </Link>
       
        <Link to="/courses/2" onClick={scrollToTop}>
          <Card img={img3} heading={'Design Thinking for Tech. Entrepreneurs'} text={'Embrace design thinking to improve product development and user experience. This course helps you integrate design thinking principles into your role as a Technology Evangelist, enabling you to foster creativity and innovation in your solutions, and ultimately drive customer satisfaction.'} />
        </Link>
        <Link to="/courses/3" onClick={scrollToTop}>
          <Card img={img4} heading={'AI for Lead Architects: A Practical Approach'} text={'Dive into the world of AI with our practical approach course. Understand how AI can be integrated into architectural solutions, learn about various AI technologies and tools, and build AI-driven applications. The course provides a solid foundation to help you effectively implement AI in your real-world projects.'} />
        </Link>
      </div>
    </div>
    </Element>
  );
};

export default Featured;
