import React from 'react'
import img2 from '../../assets/images/Frame 415.png';
import '../../styles/course/CourseAbout.css';
import { Element } from 'react-scroll'; 
const CourseAbout = () => {
  return (
    <div style={{"position": "relative"}}>
     
      <div className="courseabout-main">
        <div className="text-containera">
          <span className="heading">Trusted By 900K+ Students</span>
          <h3 className="tagline">What students say about this course</h3>
          <p className="tagline2">
            It is usually followed by people who want to improve their skills or
            prepare to enter a new industry
          </p>
          <div className='ca-d'>
            <button>Get Started</button>
          </div>
        </div>
        <div className="image-container">
          <img className="imageca" src={img2} alt="" />
        </div>
      </div>
     
    </div>
  )
}

export default CourseAbout
