import React from 'react';
import '../../styles/home/Card.css';
import {CiLogin} from 'react-icons/ci';

const Card = ({img, heading, text}) => {
  return (
    <div className='card-main'>
      <img src={img} alt=''/>
      <p className='card-heading'>{heading}</p>
      <p className='card-text'>{text}</p>
      <div className='card-btn'>
        <button>Learn More</button>
        <CiLogin/>
      </div>
    </div>
  )
}

export default Card
