import React from 'react';
import img from '../../assets/images/CoursesHeading.png';
import '../../styles/course/CourseHead.css';

const CourseHead = () => {
  return (
    <>
    <div className='coursehead-main'>
      <div className='coursehead-heading'>
            <h1>Microservices: Designing, Developing, and Deploying</h1>
            <p>This 10-week course focuses on teaching learners how to design, develop, and deploy microservices. It starts with the fundamental concepts and gradually moves to advanced topics, giving learners a thorough understanding of the entire process.</p>
      </div>
      <div className='coursehead-img'>
            <img src={img} alt=''/>
      </div>
    </div>
    <hr/>
    </>
  )
}

export default CourseHead
