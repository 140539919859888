import React from 'react';
import '../../styles/course/CourseTutor.css';
import plus from '../../assets/images/PlusCircle.png';
import minus from '../../assets/images/MinusCircle.png';

const CourseTutor = () => {
  return (
    <>
    <div className='coursetutor-main'>
      <div className='coursetutor-heading'>
        <h3>Course Tutor: Isil Celik</h3>
      </div>
      <div className='coursetutor-plus'>
      <p>Do I need to have previous experience with microservices to take this course?</p>
      <img src={plus} alt='' />
    </div>
    <div className='coursetutor-minus'>
        <div>
        <p className='coursetutor-p'>Does this course include hands-on projects?</p>
        <p className='coursetutor-minusp'>Yes, practical projects form an essential part of the course to ensure you gain hands-on experience designing, developing, and deploying microservices.</p>
        </div>
      <img src={minus} alt='' />
    </div>
    </div>
    <hr/>
    </>
    
  )
}

export default CourseTutor
