import React from 'react';
import CourseHead from './PageComponent/CourseHead';
import CourseDetails from './PageComponent/CourseDetails';
import CourseTutor from './PageComponent/CourseTutor';
import CourseTutor1 from './PageComponent/CourseTutor1';
import CourseAbout from './PageComponent/CourseAbout';
import CourseSchedule from './PageComponent/CourseSchedule';

const Course = ({ course }) => {
  if (!course) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <CourseHead name={course.name} overview={course.overview} />
      <CourseDetails eligibility={course.eligibility} courseContent={course.courseContent} />
    
      <CourseTutor faqs={course.faq} /> 
      <CourseTutor1
        tutor={course.tutor}
        tutorInfo={course.tutorInfo}
        rating={course.rating}
        coursesCount={course.coursesCount}
        studentsCount={course.studentsCount}
        tutorUrl={course.tutorUrl}
      />

      <CourseSchedule schedule={course.schedule} /> 
      <CourseAbout
        heading={course.heading}
        tagline={course.tagline}
        description={course.description}
        reviews={course.reviews} 
      />
    </div>
  );
}

export default Course;
