import React from 'react';
import '../../styles/course/CourseDetails.css';

const CourseDetails = () => {
  return (
    <div className='coursedetails-main'>
      <div className='coursedetails-1'>
            <h2>Who's This Course For?</h2>
            <p>This course is perfect for software developers, solution architects, and other technical professionals who are interested in mastering microservices architecture to build scalable, resilient, and independent software systems.</p>
      </div>
      <div className='coursedetails-1'>
      <h2>Eligibility</h2>
            <p>While prior experience with microservices is beneficial, it is not a requirement. The course is open to any tech professional with a basic understanding of software architecture and programming concepts.</p>
      </div>
      <div className='coursedetails-1' >
      <h2>Course Content</h2>
            <ol>
                <li>Introduction to microservices and architectural patterns.</li>
                <li> Designing microservices with Domain-Driven Design (DDD).</li>
                <li>Developing microservices using popular frameworks.</li>
                <li>Deploying and scaling microservices with Docker and Kubernetes.</li>
                <li>Microservices security, testing, and monitoring.</li>
                <li>Case studies and final project.</li>
            </ol>
      </div>
    </div>
  )
}

export default CourseDetails
