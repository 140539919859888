import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/course/CourseAbout.css';


const CourseAbout = ({ heading, tagline, description, reviews }) => {
  return (
    <div style={{ position: 'relative' }}>
      <div className="courseabout-main">
        <div className="text-containera">
          <span className="heading">Trusted By 900K+ Students</span>
          <h3 className="tagline">What students say about this course</h3>
          <p className="tagline2">{description}</p>
          <div className="ca-d">
            <button>Get Started</button>
          </div>
        </div>
        <div className="image-container">
          <div className="review-section">
            {reviews.map((review, index) => (
              <div className="review-item" key={index}>
                {/* <div className="avatar">
                  <img src={review.avatarUrl} alt={`Avatar of ${review.username}`} />
                </div> */}
                <div className="review-content">
                  <div className="username">{review.username}</div>
                  <div className="user-role">{review.userPosition}</div>
                  <div className="user-description">{review.userReview}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

CourseAbout.propTypes = {
  heading: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string.isRequired,
      userPosition: PropTypes.string.isRequired,
      userReview: PropTypes.string.isRequired,
      // avatarUrl: PropTypes.string.isRequired, 
    })
  ).isRequired,
};

export default CourseAbout;
