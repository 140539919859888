import React from 'react';
import '../../styles/home/Training.css';
import img from '../../assets/logo/FeaturedStar.png';
import {CiLogin} from 'react-icons/ci';

import ai from '../../assets/images/AI_expert.png';
import ailead from '../../assets/images/AI_for_lead.png'
import icons from '../../assets/images/icons.png';
import meter from '../../assets/images/meter.png';

const Training = () => {
  return (
    <>
    <div className='featured-heading' style={{margin : "2rem"}}>
       <img src={img} alt='' />
      <h1>Redefining Learning with One-on-One Training</h1>
       </div>
       <div className='featured-button'>
        <p style={{margin:"2rem"}}>At CodeCuckoo, we believe that every learner is unique, and so should be their learning journey. That's why we offer exclusively personalized 1:1 training, crafting a learning experience that fits your needs, pace, and career goals.</p>
        {/* <div className='featured-btn' style={{marginRight:"2rem"}}>
        <button>Learn More</button>
        <CiLogin style={{backgroundColor:'#501CB7', color: "white"}}/>
        </div> */}
       </div>
    <div className="cards-main-container">
    <div className="primary-cards-container">
      <div className="primary-card primary-card1-utils">
        <div className="primary-card-img">
          <img src={ai} alt="" />
        </div>
        <div style={{background:"transparent"}}>
          <span className="headingt1" >Industry Expert at Your Disposal</span>
          <h2 className="info1">
            Learn directly from industry experts who bring in their rich
            experience to mentor you. They don't just teach; they provide
            insights, share experiences, and guide you throughout your
            journey.
          </h2>
        </div>
      </div>

      <div className="primary-card primary-card2-utils">
        <div className="primary-card-img">
          <img src={ailead} alt="" />
        </div>
        <div style={{background:"transparent"}}>
          <span className="headingt">Curated Content to Match Your Goals</span>
          <h2 className="info">
            We tailor course content to match your learning objectives and
            career aspirations. Whether it's getting started with solution
            architecture, or diving into AI, we've got you covered.
          </h2>
        </div>
      </div>
    </div>
    <div className="secondary-cards-container">
      <div className="secondary-card">
        <div className="secondary-card-img">
          <img src={icons} alt="" style={{ height: '250px' }} />
        </div>
        <span className="headingt"
          >Real-World Projects and Personalized Feedback</span
        >
        <h2 className="info">
          Our 1:1 training approach allows us to incorporate real-world
          projects relevant to your field of work. Receive detailed,
          personalized feedback, helping you improve and apply your skills
          practically.
        </h2>
      </div>
      <div className="secondary-card">
        <div className="flexible-schedules-container">
          <span className="heading" style={{margin: "32px 0 31px 0"}}
            >Flexible Schedules</span
          >
          <div className="flexible-schedules-inner-container">
            <div className="flexible-schedule info">
              <span>Microservices</span>
              <span>Monday</span>
            </div>
            <div className="flexible-schedule info">
              <span>Entrepreneurs</span>
              <span>Tuesday</span>
            </div>
            <div className="flexible-schedule info">
              <span>AI for lead Architects</span>
              <span>Wednesday</span>
            </div>
          </div>
          {/* <div className="button-container">
            <button>Change Schedule</button>
          </div> */}
        </div>
        <span className="headingt">Flexible Schedules and Full Support</span>
        <h2 className="info">
          Busy schedules shouldn’t come in the way of your learning. Our 1:1
          training provides the flexibility to learn at your convenience.
          Plus, we're always here to help and support you.
        </h2>
      </div>
      <div className="secondary-card">
        <div className="flexible-schedules-container">
          <span className="heading" style={{margin: "32px 0 31px 0"}}
            >Fee Reimbursement</span
          >
          <div className="secondary-card-img2">
            <img src={meter} alt="" style={{width: "70%"}}/>
          </div>
          {/* <div className="button-container">
            <button>See More Details</button>
          </div> */}
        </div>
        <span className="headingt">Help with Course Fee Reimbursement</span>
        <h2 className="info">
          We assist you in getting your course fees reimbursed by your company
          under their Learning & Development policy, helping make your
          learning journey affordable.
        </h2>
      </div>
    </div>
  </div>
    </>
  )
}

export default Training
