const coursesData = [
  {
    id: 1,
    name: "Personalised Learning: From Solution Architect to Startup CEO",
    overview: "This 12-week comprehensive course is designed for solution architects and technical professionals looking to transition into leadership roles...",
    targetAudience: "This course is ideal for solution architects, technical leads, and other professionals in the tech industry who aspire to transition into a CEO or C-level executive role...",
    eligibility: "The course does not require any previous business knowledge or management experience. However, a strong technical background and understanding of the solution architect role are essential.",
    courseContent: [
      "Understanding the CEO's role and responsibilities in a startup.",
      "Deep dive into business strategy and market analysis.",
      "Financial management and fundraising.",
      "Sales, marketing, and customer acquisition.",
      "Team building, leadership, and talent management.",
      "Aligning technical strategy with business goals.",
      "Case study analysis and final project."
    ],
    schedule: [
      { week: "Week 1-2", text: "Understanding the CEO's role and responsibilities in a startup." },
      { week: "Week 3-4", text: "Deep dive into business strategy and market analysis." },
      { week: "Week 5-6", text: "Financial management and fundraising." },
      { week: "Mid-Course Break", text: "After completing the first 6 weeks, we will have a brief mid-course break. This break provides an excellent opportunity for you to reflect on your learning, recharge, and prepare for the upcoming intensive modules." },
      { week: "Week 9-10", text: "Team building, leadership, and talent management." },
      { week: "Week 11", text: "Aligning technical strategy with business goals." },
      { week: "Week 12", text: "Case study analysis and final project." },
    ],
    heading:"",
    tagline:"",
    rating: "4.7", 
    coursesCount: "20+", 
    studentsCount: "20k+", 
    description: "The course does not require any previous business knowledge or management experience. However, a strong technical background and understanding of the solution architect role are essential.",
    tutor: "Course Tutor: James Soffer",
    tutorUrl:"https://img.freepik.com/free-photo/young-male-holding-opened-folder-white-shirt-pants-looking-handsome_176474-17179.jpg?w=996&t=st=1694169951~exp=1694170551~hmac=ce9e139028d502cb0637e8738d75780524477ea5b7219daf8a6543443eb23d8a",
    tutorInfo: "James is a seasoned tech entrepreneur and former solution architect with over 20 years of experience in the tech industry. He has successfully transitioned from a solution architect to a CEO role at a startup and now shares his insights and experiences to guide other professionals looking to make a similar transition.",
    testimonial: "James’s course was a game-changer for me. It provided the perfect blend of tech and business insights. I particularly loved how he used his personal journey to illustrate the transition from a solution architect to a CEO...",
    faq: [
      {
        question: "Do I need any prior business or management experience for this course?",
        answer: "While it's beneficial, it's not mandatory. This course is designed to help technical professionals transition into business leadership roles."
      },
      {
        question: "Will this course cover the basics of financial management and marketing strategies?",
        answer: "Yes, the course covers key aspects of running a startup, including financial management, marketing, sales, and human resources."
      },
    ],
    reviews: [
      {
        username: "John Doe",
        userPosition: "Software Architect",
        userReview: "This course completely transformed my career. As a software architect, I always wanted to explore the business side of things. James's insights and personal journey were truly inspiring. Highly recommended!",
        // avatarUrl:"https://img.freepik.com/free-photo/half-profile-image-handsome-young-caucasian-man-with-good-skin-brown-eyes-black-stylish-hair-stubble-posing-isolated-against-blank-wall-looking-front-him-smiling_343059-4560.jpg?w=2000"
      },
    
          {
            "username": "Min Foe",
            "userPosition": "Software Architect",
            "userReview": "Provided course completely transformed my career. As a software architect, I always wanted to explore the business side of things. James's insights and personal journey were truly inspiring. Highly recommended!",
            // avatarUrl:"https://d2v5dzhdg4zhx3.cloudfront.net/web-assets/images/storypages/short/linkedin-profile-picture-maker/dummy_image/thumb/004.webp"
          },
          {
            "username": "Emily Rogers",
            "userPosition": "Data Scientist",
            "userReview": "As a data scientist, I wasn't sure if this course would be relevant to my field, but it exceeded my expectations. James's course provided valuable insights into the intersection of data science and business strategy. I appreciated the real-world examples and case studies. It's a game-changer!",
            // avatarUrl:"https://img.freepik.com/free-photo/half-profile-image-handsome-young-caucasian-man-with-good-skin-brown-eyes-black-stylish-hair-stubble-posing-isolated-against-blank-wall-looking-front-him-smiling_343059-4560.jpg?w=2000"
          }
     
    ]
  },
  {
    id: 2,
    name: "Design Thinking for Tech. Entrepreneurs",
    overview: "This 8-week course integrates design thinking into the field of SaaS products. It teaches how design thinking principles can be used to create more user-centric, innovative solutions, and facilitate better communication between teams.",
    targetAudience: "This course is designed for solution architects, system designers, tech leads, and others who want to incorporate design thinking principles into their technical decision-making process.",
    eligibility: "While the course is tailored towards professionals with a background in solution architecture or similar fields, a basic understanding of design thinking concepts would be beneficial but not essential.",
    courseContent: [
      "Introduction to Design Thinking for Technology Geeks.",
      "Empathizing with Users: Understanding Needs and Pain Points.",
      "Ideating and Prototyping: Transforming Ideas into Viable Solutions.",
      "Iterative Testing: Refining Solutions and Learning from Failures.",
      "Communicating Designs: Bridging the Gap between Teams.",
      "Case Studies and Final Project."
    ],
    schedule: [
      { week: "Week 1-2", text: "Understanding the CEO's role and responsibilities in a startup." },
      { week: "Week 3-4", text: "Deep dive into business strategy and market analysis." },
      { week: "Week 5-6", text: "Financial management and fundraising." },
      { week: "Mid-Course Break", text: "After completing the first 6 weeks..." },
      { week: "Week 9-10", text: "Team building, leadership, and talent management." },
      { week: "Week 11", text: "Aligning technical strategy with business goals." },
      { week: "Week 12", text: "Case study analysis and final project." },
    ],
    rating: "4.5", 
    coursesCount: "15+", 
    studentsCount: "15k+", 
    heading:"",
    tagline:"",
    description: "While the course is tailored towards professionals with a background in solution architecture or similar fields, a basic understanding of design thinking concepts would be beneficial but not essential.",
    tutor: "Course Tutor: Bennet Newman",
    tutorUrl:"https://img.freepik.com/free-photo/businessman-black-suit-holding-his-tasklist-makes-thumb-up_114579-15902.jpg?w=996&t=st=1694169969~exp=1694170569~hmac=c7535222813349a4c7f2659f22a5d858e5244189e79dd591c505cb9413c8ccbd",
    tutorInfo: "Bennet is an experienced UX/UI designer with a passion for design thinking. He has utilized design thinking principles extensively in his work and has successfully led teams to design user-centric technical solutions.",
    testimonial: "Design Thinking for Tech. Entrepreneurs by Bennet was an eye-opener for me. It was wonderful to learn how to integrate user-centric design thinking into solution architecture. This course will surely be an asset to any solution architect's toolbox.",
    faq: [
      {
        question: "Do I need prior experience with design thinking to take this course?",
        answer: "No, this course begins with the fundamentals of design thinking and is therefore suitable for those with no previous exposure to the concept."
      },
      {
        question: "Will I get the opportunity to work on real-life scenarios during this course?",
        answer: "Yes, you'll have the chance to work on case studies and a final project that simulate real-world scenarios, providing invaluable hands-on experience."
      },
    ],
   
      "reviews": [
        {
          "username": "Alice Smith",
          "userPosition": "Software Architect",
          "userReview": "Bennet's course on design thinking opened up a new world for me. As a software architect, I didn't realize how important user-centric design could be until I took this course. Highly recommended for tech professionals!",
          // avatarUrl:"https://img.freepik.com/free-photo/half-profile-image-handsome-young-caucasian-man-with-good-skin-brown-eyes-black-stylish-hair-stubble-posing-isolated-against-blank-wall-looking-front-him-smiling_343059-4560.jpg?w=2000"
        },
        {
          "username": "John Davis",
          "userPosition": "Product Manager",
          "userReview": "Bennet's course on design thinking transformed the way I approach product development. His practical insights and hands-on exercises helped me better understand user needs and create products that resonate with our target audience. A must for product managers.",
          // avatarUrl:"https://img.freepik.com/free-photo/half-profile-image-handsome-young-caucasian-man-with-good-skin-brown-eyes-black-stylish-hair-stubble-posing-isolated-against-blank-wall-looking-front-him-smiling_343059-4560.jpg?w=2000"
        },
        {
          "username": "Ella Martinez",
          "userPosition": "UX Designer",
          "userReview": "I'm a UX designer, and I thought I knew everything about design thinking until I took Bennet's course. It gave me a fresh perspective and enhanced my ability to collaborate effectively with cross-functional teams. Bennet's teaching style is engaging, and I recommend this course to fellow designers.",
          // avatarUrl:"https://img.freepik.com/free-photo/half-profile-image-handsome-young-caucasian-man-with-good-skin-brown-eyes-black-stylish-hair-stubble-posing-isolated-against-blank-wall-looking-front-him-smiling_343059-4560.jpg?w=2000"
        },
        {
          "username": "Mark Thompson",
          "userPosition": "Software Developer",
          "userReview": "I decided to explore design thinking as a software developer, and Bennet's course was an eye-opener. It helped me see how user-centered design principles can improve the quality of the software I create. This course is a valuable addition to any developer's skill set.",
          // avatarUrl:"https://img.freepik.com/free-photo/half-profile-image-handsome-young-caucasian-man-with-good-skin-brown-eyes-black-stylish-hair-stubble-posing-isolated-against-blank-wall-looking-front-him-smiling_343059-4560.jpg?w=2000"
        }
      ]
    },
  {
    id: 3,
    name: "AI for Lead Architects: A Practical Approach",
    overview: "This 10-week course aims to equip solution architects with a practical understanding of AI. It covers the principles of AI and machine learning, techniques for integrating AI into existing systems, and methods for managing and maintaining AI-powered solutions.",
    targetAudience: "This course is ideal for solution architects, software developers, and other technical professionals who want to learn how to leverage AI for their projects or organizations.",
    eligibility: "A fundamental understanding of solution architecture and basic programming knowledge are required. Prior experience with AI or machine learning, while beneficial, is not mandatory.",
    courseContent: [
      "Fundamentals of AI and Machine Learning.",
      "AI in the World of Solution Architecture.",
      "Techniques for Integrating AI into Existing Systems.",
      "Managing and Maintaining AI-Powered Solutions.",
      "Ethical Considerations in AI Deployment.",
      "Case Studies and Final Project."
    ],
    schedule: [
      { week: "Week 1-2", text: "Fundamentals of AI and Machine Learning." },
      { week: "Week 3-4", text: "AI in the World of Solution Architecture." },
      { week: "Week 5-6", text: "Techniques for Integrating AI into Existing Systems." },
      { week: "Week 7-8", text: "Managing and Maintaining AI-Powered Solutions." },
      { week: "Week 9", text: "Ethical Considerations in AI Deployment." },
      { week: "Week 10", text: "Case Studies and Final Project." },
    ],
    heading:"",
    tagline:"",
    rating: "4.9",
    coursesCount: "10+", 
    studentsCount: "10k+",
    description: "This course is ideal for solution architects, software developers, and other technical professionals who want to learn how to leverage AI for their projects or organizations.",
    tutor: "Course Tutor: Lisa Chin",
    tutorUrl:"https://img.freepik.com/free-photo/front-view-young-beautiful-lady-white-t-shirt-black-jeans-coat-holding-green-book-white_140725-18660.jpg?w=996&t=st=1694170230~exp=1694170830~hmac=cc3037b9f0ff08faef8d07b25da6d880bb6b74dfe907b0532ee679db4e1651bc",
    tutorInfo: "Lisa is a seasoned AI/ML specialist with a keen interest in architecting software systems and solutions. She has successfully implemented AI solutions in a variety of industries and is enthusiastic about sharing her practical knowledge and experiences.",
    testimonial: "Lisa's AI course was a game-changer for me. It bridged the gap between my solution architecture background and the world of AI. It's the perfect blend of theory and practice, and I would recommend it to any solution architect wanting to delve into AI.",
    faq: [
      {
        question: "Do I need any prior experience with AI to take this course?",
        answer: "No, the course starts with the basics of AI and Machine Learning. However, a fundamental understanding of solution architecture and basic programming knowledge are required."
      },
      {
        question: "Does this course cover the ethical considerations in AI deployment?",
        answer: "Yes, the course will touch upon the ethical aspects of AI and the responsible use of technology in its later modules."
      },
    ],
    reviews: [
      {
   username: "Sarah Smith",
   userPosition: "Lead Architect",
   userReview: "Lisa's AI course is a must for lead architects looking to stay ahead in their field. Her explanations are clear, and she provides real-world examples that make complex AI concepts easy to grasp. I found the course extremely valuable.",
  //  avatarUrl:"https://img.freepik.com/free-photo/half-profile-image-handsome-young-caucasian-man-with-good-skin-brown-eyes-black-stylish-hair-stubble-posing-isolated-against-blank-wall-looking-front-him-smiling_343059-4560.jpg?w=2000"

       },
        {
   username: "David Chen",
   userPosition: "Software Engineer",
   userReview: "I enrolled in Lisa's AI course with minimal AI background, and I'm thrilled with the results. Her teaching style is engaging, and she breaks down AI concepts into digestible pieces. This course has given me the confidence to incorporate AI into my software projects.",
  //  avatarUrl:"https://img.freepik.com/free-photo/half-profile-image-handsome-young-caucasian-man-with-good-skin-brown-eyes-black-stylish-hair-stubble-posing-isolated-against-blank-wall-looking-front-him-smiling_343059-4560.jpg?w=2000"
         }, {
   username: "Jennifer Adams",
   userPosition: "IT Manager",
   userReview: "As an IT manager, I needed to understand how AI could benefit our organization. Lisa's course not only helped me understand the potential of AI but also provided insights into how to implement AI strategies effectively. It's a valuable resource for IT professionals at all levels.",
  //  avatarUrl:"https://img.freepik.com/free-photo/half-profile-image-handsome-young-caucasian-man-with-good-skin-brown-eyes-black-stylish-hair-stubble-posing-isolated-against-blank-wall-looking-front-him-smiling_343059-4560.jpg?w=2000"
}

    ]
  }
];

export default coursesData;
