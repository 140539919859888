import React from 'react';
import CourseScheduleCard from './CourseScheduleCard';
import '../../styles/course/CourseSchedule.css';

const CourseSchedule = ({ schedule }) => {
  return (
    <>
      <div className='courseschedule-main'>
        <h2>Course Schedule</h2>
        {schedule.map(item => (
          <CourseScheduleCard key={item.week} week={item.week} text={item.text} />
        ))}
      </div>
      <hr />
    </>
  );
}

export default CourseSchedule;
