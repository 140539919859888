import React from 'react';
import PropTypes from 'prop-types'; 
import '../../styles/course/CourseHead.css';
import img from '../../assets/images/CoursesHeading.png';
const CourseHead = ({ name, overview }) => {
  return (
    <div className="coursehead-main">
      <div className="coursehead-heading">
        <h1>{name}</h1>
        <p>{overview}</p>
      </div>
      <div className='coursehead-img'>
            <img src={img} alt=''/>
      </div>
    </div>
  );
};


CourseHead.propTypes = {
  name: PropTypes.string.isRequired,
  overview: PropTypes.string.isRequired,
};

export default CourseHead;
